<template>
    <div class="affiche" v-if="dialogVisible">
        <div class="conbox">
            <div class="titbox">
                <p class="tit">{{tit}}</p>
                <span class="el-icon-close" @click="dele()"></span>
            </div>
            <div class="detbox">                
                {{det}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "affiche",
        data() {
            return {
                dialogVisible: false,
                tit:"",
                det:""
            }
        },
        created(){
            let that=this;
            that.axios({
                url: this.$store.state.baseUrl + '/api/notice/notice/getSysNotice',
                method: 'POST',
                params:{
                    sys_type:2
                }
            }).then(function(res) {
                // console.log(res)
                if(res.data.code==200){
                    that.tit=res.data.data.message_title
                    that.det=res.data.data.message
                    if(localStorage.getItem("message_id")){
                    if(localStorage.getItem("message_id")!=res.data.data.message_id){
                        that.dialogVisible=true
                        localStorage.setItem("message_id",res.data.data.message_id)
                    }
                    }else{                    
                        localStorage.setItem("message_id",res.data.data.message_id)
                        that.dialogVisible=true
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
        },
        methods: {
            dele:function(){
                this.dialogVisible=false
            }
        }
    }
</script>

<style scoped lang="scss">
    .affiche{
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        top: 0;
        left: 0;
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;

        .conbox{
            min-width: 300px;
            min-height: 300px;
            max-width: 90%;  
            max-height: 90%;          
            background: #FFFFFF;
            border-radius: 8px;
            overflow: hidden;
            
            .titbox{
                position: relative;
                background:url(../../../image/index2/popbj.png) no-repeat center center;
                background-size: 101% 101%;
                padding: 25px 45px 20px;
                
                p{
                    width: 100%;                    
                    font-size: 24px;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin: 0;
                }
                span{
                    position: absolute;
                    top:50%;
                    right: 20px;
                    transform: translateY(-50%);
                    font-size: 24px;
                    font-weight: bold;
                    color: #fff;
                    cursor: pointer;
                }
            }
            .detbox{
                padding:30px 30px 50px;
                text-align: left;                
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 1.8;
            }
        }
    }
    @media screen and (max-width: 899px){
        .affiche .conbox{
            .titbox{
                padding: 15px 40px 10px;

                p{
                    font-size: 18px;
                }
            }
            .detbox{
                padding: 15px 15px 25px;
            }

        }
        

    }

</style>

<template>
    <div class="index-box">
        <div class="top-nav" :style="'background:'+background +';' + (box_shadow?'box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.05)':'')">
            <div class="nav-content">
                <div class="logobox">
                    <img src="../../image/login/logo-1.png" alt="" @click="tapToggle('index')">
                </div>
                <div class="navbox">
                    <ul>
                        <li><a href="javascript:;" :class="C_activate=='index'?'activate':''" @click="tapToggle('index')">首页</a></li>
                        <li><a href="javascript:;">雇主产品 <i class="iconfont iconai-arrow-down"></i></a>
                            <div class="childbox" :style="box_shadow?'height:0;':''">
                                <!--  @click="routerTolist('006151')"  -->
                                <div>
                                    <div><img src="../../image/index2/top1.png" alt=""> 通讯电力雇主险</div>
                                    <ul>
                                        <li>· 真正的性价比，通讯电力行业的首选</li>
                                        <li>· 支持按月投保，短期用工更方便</li>
                                        <li>· 万元以下理赔，当天到账</li>
                                    </ul>
                                </div>
                                <!--  @click="routerTodetail('006142')" -->
                                <div>
                                    <div><img src="../../image/index2/top2.png" alt=""> 机械加工制造业雇主险</div>
                                    <ul>
                                        <li>· 行业覆盖广，可保职业多 </li>
                                        <li>· 支持按月投保，短期用工更方便</li>
                                        <li>· 一线保险公司承保，更放心</li>
                                    </ul>
                                </div>
                                <!--   @click="routerTolist('006141')" -->
                                <div>
                                    <div><img src="../../image/index2/top3.png" alt=""> 物流行业雇主险</div>
                                    <ul>
                                        <li>· 减少物流企业用工风险，保障老板收益</li>
                                        <li>· 支持按月投保，短期用工更方便</li>
                                        <li>· 灵活投保，增加替换随时操作</li>
                                    </ul>
                                </div>
                                <!--  @click="routerTodetail('006152')" -->
                                <div>
                                    <div><img src="../../image/index2/top4.png" alt=""> 科技、服务行业雇主险</div>
                                    <ul>
                                        <li>· 可扩展十级10%，超高性价比</li>
                                        <li>· 支持按月投保，短期用工更方便</li>
                                        <li>· 行业覆盖广，可保职业多</li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li><a href="javascript:;" @click="tappop(1)">保费价格</a></li>
                        <li><a href="javascript:;" :class="C_activate=='serve'?'activate':''" @click="tapToggle('serve')">服务优势</a></li>
                        <li><a href="javascript:;" :class="C_activate=='industry'?'activate':''" @click="tapToggle('industry')">关于锐鹿</a></li>
                        <li><a href="javascript:;" :class="C_activate=='contact'?'activate':''" @click="tapToggle('contact')">联系我们</a></li>
                        <li class="xshi120"><a href="tel:400-616-2199"><img src="../../image/index2/navtal.png" alt=""></a></li>
                    </ul>
                     <button @click="goLogin()">{{login_text}} <!--<div class="ps"><i></i> 渠道入口搬到这里来了</div> --></button>
                </div>
            </div>
        </div>
        <div class="top-nav-app" :style="(box_shadow?'box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.05)':'')">
            <div class="top">
                <img src="../../image/login/logo-1.png" alt="">
                <div class="nav-app-button"  :class="nav==1?'activate':''" @click="navopen()">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div class="drop-down" :class="nav==1?'activate':''">
                <ul>
                    <li><a href="javascript:;"  :class="C_activate=='index'?'activate':''" @click="tapToggle('index')">首页</a></li>
                    <li><a href="javascript:;" @click="drop_down()">雇主产品 <i class="iconfont iconai-arrow-down"></i></a>
                        <div class="childbox" :class="navdrop==1?'activate':''">
                            <div>
                                <div><img src="../../image/index2/top1.png" alt=""> 通讯电力雇主险</div>
                                <ul>
                                    <li>· 真正的性价比，通讯电力行业的首选</li>
                                    <li>· 支持按月投保，短期用工更方便</li>
                                    <li>· 万元以下理赔，当天到账</li>
                                </ul>
                            </div>
                            <div>
                                <div><img src="../../image/index2/top2.png" alt=""> 机械加工制造业雇主险</div>
                                <ul>
                                    <li>· 行业覆盖广，可保职业多 </li>
                                    <li>· 支持按月投保，短期用工更方便</li>
                                    <li>· 一线保险公司承保，更放心</li>
                                </ul>
                            </div>
                            <div>
                                <div><img src="../../image/index2/top3.png" alt=""> 物流行业雇主险</div>
                                <ul>
                                    <li>· 减少物流企业用工风险，保障老板收益</li>
                                    <li>· 支持按月投保，短期用工更方便</li>
                                    <li>· 灵活投保，增加替换随时操作</li>
                                </ul>
                            </div>
                            <div>
                                <div><img src="../../image/index2/top4.png" alt=""> 科技、服务行业雇主险</div>
                                <ul>
                                    <li>· 可扩展十级10%，超高性价比</li>
                                    <li>· 支持按月投保，短期用工更方便</li>
                                    <li>· 行业覆盖广，可保职业多</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li><a href="javascript:;" :class="C_activate=='serve'?'activate':''" @click="tapToggle('serve')">服务优势</a></li>
                    <li><a href="javascript:;" :class="C_activate=='industry'?'activate':''" @click="tapToggle('industry')">关于锐鹿</a></li>
                    <li><a href="javascript:;" :class="C_activate=='contact'?'activate':''" @click="tapToggle('contact')">联系我们</a></li>
                </ul>
            </div>
        </div>
        <div class="index-content" id="index">
            <el-carousel :height="carouselHeight">
                <el-carousel-item >
                    <!--  @click="routerTolist('006151')" -->
                    <img width="100%"  src="../../image/index2/com-banner-1.png" alt="">
                </el-carousel-item>
                <el-carousel-item >
                    <!--  @click="routerTolist('006141')"  -->
                    <img width="100%" src="../../image/index2/com-banner-2.png" alt="">
                </el-carousel-item>
                <el-carousel-item >
                    <!-- @click="routerTodetail('006152')"  -->
                    <img width="100%"  src="../../image/index2/com-banner-3.png" alt="">
                </el-carousel-item>
                <el-carousel-item >
                    <!--  @click="routerTodetail('006142')" -->
                    <img width="100%" src="../../image/index2/com-banner-4.png" alt="">
                </el-carousel-item>
                <el-carousel-item >
                    <img width="100%" src="../../image/index/cn-banner-3.png" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="index1">
            <div class="index1-content">  
                <p>尽心13年，只做一件事！更专业的行业雇主险解决方案提供商</p>
                <p>潜心深耕三大保险公司深度合作 <span>通讯电力、物流、机械加工、服务行业    中国人保、中国平安、太平洋保险</span>三大保险公司深度合作</p>
                <img src="../../image/index2/index1.gif" alt="">
                <!-- <div class="index1-bottom">
                    <p>合作伙伴</p>
                    <div>
                        <img src="../../image/index2/rbao.png" alt="">
                        <img src="../../image/index2/pan.png" alt="">
                        <img src="../../image/index2/baigb.png" alt="">
                        <img src="../../image/index2/huaan.png" alt="">
                    </div>
                </div> -->
            </div>
        </div>
        <div class="industry" id="industry">
            <div class="industry-content">
                <p>行业领先 企业选择</p>
                <div>
                    <img src="../../image/index2/industry.png" alt="">
                    <div>
                        <p>为全行业奋斗者提供持续的保险服务 </p>
                        <p><span>众多</span>企业的选择</p>
                        <p>业务覆盖<span>多</span>个省，<span>多</span>个城市</p>
                        <p><span>大平台</span>有保障</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="serve" id="serve">
            <div class="serve-content">
                <p>买保险 不踩坑 快理赔 优服务</p>
                <p>全心全意为客户服务  打造全流程<span>智能理赔新模式</span></p>
                <div class="flex-box">
                    <div>
                       <div class="imgbox">
                            <img src="../../image/index2/serve1.png" alt="">
                        </div>
                       <div class="textbox">
                           <p>真正的性价比</p>
                           <p>针对每个行业量身定制</p>
                           <p>保障全面，保额高，保费远低于行业水平</p>
                       </div>
                    </div>
                    <div>
                       <div class="imgbox">
                            <img src="../../image/index2/serve2.png" alt="">
                        </div>
                       <div class="textbox">
                           <p>在线投保、理赔，让投保更便捷</p>
                           <p>全心全意为客户服务，打造全流程智能投保、理赔新模式</p>
                       </div>
                    </div>
                    <div>
                       <div class="imgbox">
                            <img src="../../image/index2/serve3.png" alt="">
                        </div>
                       <div class="textbox">
                           <p>理赔简单 · 服务贴心</p>
                           <p>在线报案理赔，理赔客服1对1服务</p>
                       </div>
                    </div>
                    <div>
                        <div class="imgbox">
                            <img src="../../image/index2/serve4.png" alt="">
                        </div>
                       <div class="textbox">
                           <p>合作共赢</p>
                           <p>以客户为中心，推动保险行业变革转型，提高服务效率</p>
                           <p>贴合用户需求，基于保险科技DNA，打造全行业服务新生态，为智慧保险</p>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="cooperation" id="contact">
            <div class="cop-content">
                <p>合作客户</p>
                <div class="imgbox">
                    <img src="../../image/index2/rbao-1.png" alt="">
                    <img src="../../image/index2/pan-1.png" alt="">
                    <img src="../../image/index2/baigb-1.png" alt="">
                    <img src="../../image/index2/huaan-1.png" alt="">
                </div>
            </div>
        </div> -->
        
        <div class="contentbox">
            <div class="wxbtn">
                <div>
                    <img src="../../image/index2/wx.png" alt="">
                    <p>企业微信</p>
                </div>
                <div>
                    <img src="../../image/login/sm.jpg" alt="">
                    <p>锐鹿客服专家</p>
                </div>
            </div>
            <div class="qqbtn">
                <a  href="http://wpa.qq.com/msgrd?v=3&uin=3275642048&site=qq&menu=yes" target="_blank">
                    <img src="../../image/index2/kf2.png" alt="">
                    <p>在线咨询</p>
                </a>
            </div>
        </div>
        <div class="bottomtanc" :class="bottompop==1?'activate':''">
            <p>客服小姐姐24小时为您在线提供保障方案、保费价格、理赔范围等咨询服务</p>
            <div>
                <input type="text" v-model="bottomphone" @change="bottomphoneis" placeholder="请填写您的手机号">
                <div @click="tappopbtn()">我要咨询</div>
            </div>
            <i class="el-icon-close" @click="tappop(0)"></i>
        </div>

        <front-bottom></front-bottom>
        <affiche></affiche>
    </div>
</template>
<script>
    import frontBottom from "../../components/front/frontBottom";
    import affiche from "../../components/back/dialog/affiche";
    export default {
        name: "index2",
        components: {
            affiche,
            frontBottom
        },
        data() {
            return {
                carouselHeight:'660px',
                nav:"0",
                navdrop:"0",
                box_shadow: false,//阴影
                background:"transparent",
                C_activate:"index",
                login_text: "保单查询",
                bottompop:"0",//底部弹窗
                bottomphone:""//底部弹窗输入的手机号
            }
        },
        created() {
            this.login_type = this.$store.state.login_type
            if (this.login_type != 1) {
                this.login_text = "保单查询"
            } else {
                this.login_text = "个人中心"
            }
            (function (w, d, e, x) {  w[e] = function () {    w.cbk = w.cbk || [];    w.cbk.push(arguments);  };  x = d.createElement('script');  x.async = true;  x.id = 'zhichiScript';  x.src = 'https://ruilu.sobot.com/chat/frame/v2/entrance.js?sysnum=4f28d73e298b4c06a6c1d481c35d5ab8&channelid=2';  d.body.appendChild(x);})(window, document, 'zc');
        },
        mounted() {
            // 事件监听滚动条
            window.addEventListener("scroll", this.shadowScroll);
            //立即执行
            window.onresize = () => {
            return (() => {
                this.watchWidth();
            })();
            };
            this.watchWidth();
        },
        methods: {
            goLogin: function () {//点击登陆、个人中心
                /* if (this.login_type != 1) {
                    this.$router.push({name: 'login'})
                } else {
                    this.$router.push({name: 'guaranteeSlip'})
                } */
                alert("请联系客服人员，谢谢！");
            },
            navopen:function(){
                console.log(4555)
                if(this.nav==0){
                    this.nav=1
                }else{ 
                    this.nav=0
                }
            },
            drop_down:function(){
                if(this.navdrop==0){
                    this.navdrop=1
                }else{
                    this.navdrop=0
                }
            },
            shadowScroll: function () {//滚动添加阴影
                let offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                if (offset > 50) {
                    this.box_shadow = true
                    this.background="#fff"
                } else {
                    this.box_shadow = false
                    this.background="transparent"
                }
            },
            //点击切换
            tapToggle: function (e) {
                this.C_activate = e
                let anchorElement = document.getElementById(e);
                if (anchorElement) {
                    anchorElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
                }
            },
            routerTolist: function (pro_num) {//有工种页转跳列表页
                this.$router.push({
                    name: 'employerInsurance',
                    query: {
                        pro_num: pro_num,
                    }
                })
            },
            routerTodetail: function (pro_num) {//没有工种页转跳详情页
                this.$router.push({
                    name: 'detail',
                    query: {
                        pro_num: pro_num,
                    }
                })
            },
            watchWidth:function(){
                //当前window 宽
                let centerWidth = document.documentElement.scrollWidth;
                //最小宽度 1440
                this.carouselHeight = Math.ceil(centerWidth * 0.34) + 'px';
            },
            tappop:function(e){//底部弹窗
                this.bottompop=e
            },
            // 底部手机号验证
            bottomphoneis:function(){
                if (!(/^1\d{10}$/.test(this.bottomphone))) {
                    this.bottomphone=""
                    this.$message.error('手机号不正确');
                }
            },
            // 底部弹窗提交
            tappopbtn:function(){
                let phone=this.bottomphone
                let that=this
                if(phone){
                that.axios({
                    url: this.$store.state.baseUrl + '/api/channel/channels/setUserPhone',
                    method: 'POST',
                    params:{
                        phone:phone
                    }
                }).then(function (res) {
                    console.log(res)
                    if (res.data.code == 200) {
                        that.$alert('稍后会有客服小姐姐联系您', '提交成功', {
                            confirmButtonText: '确定',
                            callback: () => {
                               that.bottomphone='',
                               that.bottompop="0"

                            }
                        });
                    } else {
                       that.$message.error('发送失败请稍后重试');  
                    }
                }).catch(function (error) {
                    console.log(error);
                });
                }else{
                  that.$message.error('手机号不不可为空');  
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    @media screen and (max-width: 899px) {
        .index-box {
            width: 100%;
            box-sizing: border-box;
            position: relative;
        }
        .top-nav{
            display: none;
        }
        .top-nav-app{
            height: 50px;
            padding:0 30px;
            box-sizing: border-box;
            position: fixed;
            top:0;
            z-index: 9;
            width: 100%;
            background:#fff;

            .top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                box-sizing: border-box;
                padding: 5px 0;

                img{
                    max-width: calc(100% - 130px);
                    max-height: 100%;
                }

                .nav-app-button{
                    position: relative;
                    width:20px;
                    & > span{
                        width:20px;
                        height: 2px;
                        background: #FE6612;
                        border-radius: 5px;
                        display:block;
                        position: absolute;
                        transition: all 0.25s;
                    }
                    & > span:nth-child(1){
                        top: -7px;
                    }
                    & > span:nth-child(3){
                        top: 7px;
                    }
                }
                .nav-app-button.activate{
                    & > span:nth-child(1){
                        transform: rotate(45deg);
                        top:0
                    }
                    & > span:nth-child(2){
                        opacity: 0;
                    }
                    & > span:nth-child(3){
                        transform: rotate(-45deg);
                        top:0
                    }
                }
            }
            .drop-down{
                position: absolute;
                top:50px;
                left: 0;
                width:100%;
                z-index: 9;
                background:rgba(255,255,255,0.96);
                height: 0;
                overflow: hidden;
                overflow-y: auto;
                box-sizing: border-box;
                transition: all 0.25s;
                
                & > ul{
                    padding:0 0 30px;
                    margin:0;
                    list-style: none;
                    text-align: left;

                    & > li a{
                        font-size:16px;
                        font-family:PingFang SC;
                        font-weight:400;
                        color:rgba(51,51,51,1);
                        text-decoration: none;
                        padding:15px 30px;
                        display:inline-block;
                        width: 100%;
                        box-sizing: border-box;
                        i{
                            font-size: 14px;
                        }
                    }
                    .childbox{
                        width: 100%;
                        padding:0 30px;
                        background:rgba(255,238,212,0.8);
                        box-sizing: border-box;
                        height: 0;
                        overflow: hidden;
                        transition: all 0.25s;

                        & > div{
                            width: 100%;
                            & > div{
                                font-size:15px;
                                font-family:PingFang SC;
                                font-weight:400;
                                color:rgba(51,51,51,1);
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                & >img{
                                    width: 25px;
                                    margin-right: 10px;
                                }
                            }
                            ul{
                                padding:0;
                                margin: 0;
                                list-style: none;
                                margin:10px 0;
                                
                                &>li{
                                    font-size:14px;
                                    font-family:PingFang SC;
                                    color:rgba(102,102,102,1); 
                                    line-height: 2;
                                }
                            }
                        }
                    }
                    .childbox.activate{
                        padding: 15px 30px;
                        height: 534px;
                    }
                }
            }
            .drop-down.activate{
                height: 500px;
            }
        }
        .index-content{
            margin-top:50px;
        }
        .index1{
            width: 100%;

            .index1-content{
                width:100%;
                padding: 0 30px;
                margin: 20px auto;
                box-sizing: border-box;

                p{
                    margin:0;
                }
                & > p:nth-child(1){
                    font-size:18px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(51,51,51,1);
                }
                & > p:nth-child(2){
                    font-size:15px;
                    font-family:PingFang SC;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    margin-top:15px;
                    span{
                        color:#FF822D;
                    }
                }
                & > img{
                    width: 80%;
                    text-align: center;
                    margin:50px auto;
                }
                & > div{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    p{
                        font-size:12px;
                        font-family:PingFang SC;
                        font-weight:400;
                        color:rgba(51,51,51,1);
                        margin: 0;
                    }
                    &>div img{                        
                        margin: 0 5px;
                        height: 40px;
                    }
                }
            }
        }
        .industry{
            background: url(../../image/index2/industrybj.png) no-repeat top center;
            background-size: auto 100%;

           .industry-content{
               width: 100%;
               margin:0 auto;
               padding: 40px 30px 40px;
               box-sizing: border-box;
               
               & > p{
                    font-size:24px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(255,255,255,1); 
                    margin:0;
                    text-align: center;
                    margin-bottom:20px;
               }
               &>div{
                   display: flex;
                   justify-content:center;
                   align-items: center;
                   flex-wrap: wrap;

                    img{
                        max-width:100%;
                    }
                   &>div{
                       text-align: left;
                       
                       &> p{
                            font-size:16px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:rgba(255,255,255,1);
                            margin:10px 0;
                            
                            span{
                                font-size: 18px;
                            }
                       }
                   }
               }
           }
        }
        .serve{

            .serve-content{
                width: 100%;
                margin:50px auto;

                &>p:nth-child(1){
                    font-size:22px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(51,51,51,1);
                    margin:0
                }
                &>p:nth-child(2){
                    font-size: 16px;
                    margin: 10px 0;
                    font-family:PingFang SC;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    span{
                        color: #FF7F32;
                    }
                }

                .flex-box{
                   & > div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        box-sizing: border-box;

                        .imgbox{
                            width: 100%;
                            text-align: center;
                            box-sizing: border-box;
                            img{
                                max-width: 100%;
                            }
                        }
                        .textbox{
                            width: 100%;
                            padding-left:50px;
                            box-sizing: border-box;
                        }
                    }
                    .textbox{
                        margin-bottom: 25px;

                        & > p{
                            margin: 0;
                            text-align: left;
                        }
                        & > p:nth-child(1){
                            font-size:18px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:rgba(255,127,50,1);
                        }
                        & > p:nth-child(2){
                            font-size:16px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:#333;
                            margin:10px 0;
                        }
                        & > p:nth-child(3){
                            font-size:14px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:#666;
                        }
                    }
                }
            }
        }
        .cooperation{
            background: url(../../image/index2/coll.png) no-repeat top center;
            background-size: auto 100%; 
            box-sizing: border-box;

            .cop-content{
                width: 100%;
                padding:40px 30px 70px;
                margin: 0 auto;
                box-sizing: border-box;
                
                &>p{
                    font-size:22px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(255,255,255,1); 
                    margin:0 0 20px
                }
                & > .imgbox{
                    display: flex;
                    justify-content: space-between;
                    box-sizing: border-box;

                    img{
                        width:22%
                    }
                }

            }
        }
    }

    @media screen and (max-width: 1280px) and (min-width: 900px) {
        .index-box {
            overflow: hidden;
            box-sizing: border-box;
            position: relative;
        }
        .top-nav-app , .top-nav .xshi120{
            display: none;
        }
         .top-nav{
            width:100%;
            position: fixed;
            top:0;
            z-index: 9;

            .nav-content{
                width: 880px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 70px;
                position: relative;

                .logobox{
                    width: 350px;
                    text-align: left;
                }
                .navbox{
                    width: calc(100% - 350px);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    & > ul{
                        padding: 0;
                        list-style: none;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        & > li a.activate,& > li:hover a{
                            color:rgba(255,130,45,1);
                            transition: all 0.25s;
                        }
                        & > li.activate .childbox,& > li:hover .childbox{
                            height: 180px !important;
                        }
                        & > li{
                            margin: 0 10px;

                            a{
                                font-size:14px;
                                font-family:PingFang SC;
                                font-weight:400;
                                color:rgba(51,51,51,1);
                                text-decoration: none;

                                i{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    button{
                        background-color: transparent;
                        width: 80px;
                        height: 34px;
                        border: 0px solid #333;
                        color: #333;
                        border-radius: 5px;
                        cursor: pointer;                     
                        background: linear-gradient(180deg, #FFCC00, #FF8D00);
                        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
                        border-radius: 18px;
                        font-size: 14px;
                        color:#fff;
                    }
                    button:hover{
                        color:#fff;
                        background: linear-gradient(180deg,  #FF8D00,#FFCC00);
                        transition: all 0.25s;
                    }
                    button:focus{
                        outline: 0;
                    }
                    ul li .childbox{
                        position: absolute;
                        top: 100%;
                        width: 100%;
                        left: 0;
                        display: flex;
                        justify-content: space-between;
                        overflow: hidden;
                        transition: all 0.25s;
                        height: 180px;
                       
                        & > div{
                            padding: 15px;
                            background: #fff;
                            width: 25%;
                            cursor: pointer;
                            transition: all 0.25s;

                            & > div{
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                font-size:15px;
                                font-family:PingFang SC;
                                font-weight:400;
                                color:rgba(51,51,51,1);
                                line-height:36px;

                                img{
                                    margin-right: 10px;
                                    width: 30px;
                                }
                            }
                            ul{
                                padding: 0;
                                list-style: none;
                                text-align: left;
                                margin-top:10px;

                                li{
                                    font-size:12px;
                                    font-family:PingFang SC;
                                    font-weight:400;
                                    color:rgba(102,102,102,1);
                                    line-height:1.5;
                                    padding:5px 0;
                                }
                            }
                        }
                        & > div:hover{
                            background:rgba(255,248,238,1);
                        }          
                    }
                }

            }
        }
        .index1{
            width: 100%;

            .index1-content{
                width:880px;
                margin: 50px auto 30px;

                p{
                    margin:0;
                }
                & > p:nth-child(1){
                    font-size:24px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(51,51,51,1);
                }
                & > p:nth-child(2){
                    font-size:15px;
                    font-family:PingFang SC;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    margin-top:20px;
                    span{
                        color:#FF822D;
                    }
                }
                & > img{
                    width: 80%;
                    text-align: center;
                    margin:50px auto;
                }
                & > div{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    p{
                        font-size:20px;
                        font-family:PingFang SC;
                        font-weight:400;
                        color:rgba(51,51,51,1);
                        margin: 0 30px 0 60px;
                    }
                    &>div img{                        
                        margin: 0 10px;
                        height: 70px;
                    }
                }
            }
        }
        .industry{
            background: url(../../image/index2/industrybj.png) no-repeat top center;
            background-size: auto 100%;

           .industry-content{
               width: 880px;
               margin:0 auto;
               padding: 40px 30px 40px;
               box-sizing: border-box;
               
               & > p{
                    font-size:30px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(255,255,255,1); 
                    margin:0;
                    text-align: center;
                    margin-bottom:20px;
               }
               &>div{
                   display: flex;
                   justify-content:flex-start;
                   align-items: center;

                   &>div{
                       margin-left:40px;
                       text-align: left;
                       &> p{
                            font-size:20px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:rgba(255,255,255,1);
                            margin:10px 0;
                            
                            span{
                                font-size: 24px;
                            }
                       }
                   }
               }
           }
        }
        .serve{

            .serve-content{
                width: 880px;
                margin:50px auto;

                &>p:nth-child(1){
                    font-size:30px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(51,51,51,1);
                    margin:0
                }
                &>p:nth-child(2){
                    font-size: 16px;
                    margin: 10px 0;
                    font-family:PingFang SC;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    span{
                        color: #FF7F32;
                    }
                }

                .flex-box{
                   & > div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        box-sizing: border-box;

                        .imgbox{
                            width: 40%;
                            text-align: center;
                            box-sizing: border-box;
                            img{
                                max-width: 100%;
                            }
                        }
                        .textbox{
                            width: 60%;
                            padding-left:50px;
                            box-sizing: border-box;
                        }
                    }
                    div:nth-child(odd){
                        flex-direction: row-reverse;
                        box-sizing: border-box;
                        .imgbox{
                            width: 60%;
                            text-align: center;
                            padding-left:50px;
                            box-sizing: border-box;
                            img{
                                max-width: 100%;
                            }
                        }
                        .textbox{
                            width: 40%;
                            box-sizing: border-box;
                        }
                    }
                    .textbox{
                        & > p{
                            margin: 0;
                            text-align: left;
                        }
                        & > p:nth-child(1){
                            font-size:24px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:rgba(255,127,50,1);
                        }
                        & > p:nth-child(2){
                            font-size:18px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:#333;
                            margin:10px 0;
                        }
                        & > p:nth-child(3){
                            font-size:14px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:#666;
                        }
                    }
                }
            }
        }
        .cooperation{
            background: url(../../image/index2/coll.png) no-repeat top center;
            background-size: auto 100%; 

            .cop-content{
                width: 880px;
                padding:40px 30px 70px;
                margin: 0 auto;
                
                &>p{
                    font-size:32px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(255,255,255,1); 
                    margin:0 0 50px
                }
                & > .imgbox{
                    display: flex;
                    justify-content: space-between;

                    img{
                        width:22%
                    }
                }

            }
        }
    }

    @media screen and (min-width: 1280px) {
        .index-box {
            box-sizing: border-box;
            position: relative;
        }
        .top-nav-app{
            display: none;
        }
        .top-nav{
            width:100%;
            position: fixed;
            top:0;
            z-index: 9;

            .nav-content{
                width: 1200px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 70px;
                position: relative;

                .logobox{
                    width: 350px;
                    text-align: left; 
                }
                .navbox{
                    width: calc(100% - 350px);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    & > ul{
                        padding: 0;
                        list-style: none;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        & > li a.activate,& > li:hover a{
                            color:rgba(255,130,45,1);
                            transition: all 0.25s;
                        }
                        & > li.activate .childbox,& > li:hover .childbox{
                            height: 210px !important;
                        }
                        & > li{
                            margin: 0 12px;

                            a{
                                font-size:16px;
                                font-family:Helvetica;
                                font-weight:bold;
                                color:rgba(51,51,51,1);
                                text-decoration: none;
                                text-shadow: 1px 4px 20px rgb(216, 216, 216);

                                i{
                                    font-size: 14px;
                                }
                            }
                            
                        }
                    }
                    button{
                        background-color: transparent;
                        width: 90px;
                        height: 34px;
                        border: 0px solid #333;
                        color: #333;
                        border-radius: 5px;
                        cursor: pointer;                     
                        background: linear-gradient(180deg, #FFCC00, #FF8D00);
                        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
                        border-radius: 18px;
                        font-size: 14px;
                        color:#fff;
                    }
                    button:hover{
                        color:#fff;
                        background: linear-gradient(180deg,  #FF8D00,#FFCC00);
                        transition: all 0.25s;
                    }
                    button:focus{
                        outline: 0;
                    }
                    ul li .childbox{
                        position: absolute;
                        top: 100%;
                        width: 100%;
                        left: 0;
                        display: flex;
                        justify-content: space-between;
                        overflow: hidden;
                        transition: all 0.25s;
                        height:0;
                        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.08);
                       
                        & > div{
                            padding: 20px 30px;
                            background: #fff;
                            width: 25%;
                            cursor: pointer;
                            transition: all 0.25s;

                            & > div{
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                font-size:18px;
                                font-family:PingFang SC;
                                font-weight:400;
                                color:rgba(51,51,51,1);
                                line-height:36px;

                                img{
                                    margin-right: 10px;
                                }
                            }
                            ul{
                                padding: 0;
                                list-style: none;
                                text-align: left;
                                margin-top:10px;

                                li{
                                    font-size:12px;
                                    font-family:'MicrosoftYaHei',"微软雅黑";
                                    // font-weight:600;
                                    color:rgba(102,102,102,1);
                                    line-height:40px;
                                }
                            }
                        }
                        & > div:hover{
                            background:rgba(255,248,238,1);
                        }          
                    }
                }

            }
        }
        .index1{
            width: 100%;

            .index1-content{
                width:1200px;
                margin:80px auto 60px;

                p{
                    margin:0;
                }
                & > p:nth-child(1){
                    font-size:34px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(51,51,51,1);
                }
                & > p:nth-child(2){
                    font-size:18px;
                    font-family:PingFang SC;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    margin-top:20px;
                    span{
                        color:#FF822D;
                    }
                }
                & > img{
                    width: 80%;
                    text-align: center;
                    margin:100px auto;
                }
                & > div{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    p{
                        font-size:26px;
                        font-family:PingFang SC;
                        font-weight:400;
                        color:rgba(51,51,51,1);
                        margin:0 65px 0 130px;
                    }
                    &>div img{
                        margin:0 15px;
                    }
                }
            }
        }
        .industry{
            background: url(../../image/index2/industrybj.png) no-repeat top center;
            background-size: auto 100%;

           .industry-content{
               width: 1200px;
               margin:0 auto;
               padding:90px 30px 130px;
               
               & > p{
                    font-size:48px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(255,255,255,1); 
                    margin:0;
                    text-align: center;
                    margin-bottom: 90px;
               }
               &>div{
                   display: flex;
                   justify-content:flex-start;
                   align-items: center;

                   &>div{
                       margin-left:40px;
                       text-align: left;
                       &> p{
                            font-size:26px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:rgba(255,255,255,1);
                            margin:10px 0;
                            
                            span{
                                font-size: 44px;
                            }
                       }
                   }
               }
           }
        }
        .serve{

            .serve-content{
                width: 1200px;
                margin:100px auto;

                &>p:nth-child(1){
                    font-size:48px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(51,51,51,1);
                    margin:0
                }
                &>p:nth-child(2){
                    font-size:24px;
                    font-family:PingFang SC;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    span{
                        color: #FF7F32;
                    }
                }

                .flex-box{
                   & > div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        box-sizing: border-box;

                        .imgbox{
                            width: 40%;
                            text-align: center;
                            box-sizing: border-box;
                            img{
                                max-width: 100%;
                            }
                        }
                        .textbox{
                            width: 45%;
                            padding-left:50px;
                            box-sizing: border-box;
                        }
                    }
                    & > div+div{
                        margin-top:60px;
                    }
                    div:nth-child(odd){
                        flex-direction: row-reverse;
                        box-sizing: border-box;
                        .imgbox{
                            width: 50%;
                            text-align: center;
                            padding-left:50px;
                            box-sizing: border-box;
                            img{
                                max-width: 100%;
                            }
                        }
                        .textbox{
                            width: 45%;
                            box-sizing: border-box;
                        }
                    }
                    .textbox{
                        & > p{
                            margin: 0;
                            text-align: left;
                        }
                        & > p:nth-child(1){
                            font-size:40px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:rgba(255,127,50,1);
                        }
                        & > p:nth-child(2){
                            font-size:30px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:#333;
                            margin:10px 0;
                        }
                        & > p:nth-child(3){
                            font-size:20px;
                            font-family:PingFang SC;
                            font-weight:400;
                            color:#666;
                        }
                    }
                }
            }
        }
        .cooperation{
            background: url(../../image/index2/coll.png) no-repeat top center;
            background-size: auto 100%; 

            .cop-content{
                width: 1200px;
                padding:60px 30px 100px;
                margin: 0 auto;
                
                &>p{
                    font-size:48px;
                    font-family:PingFang SC;
                    font-weight:600;
                    color:rgba(255,255,255,1); 
                    margin:0 0 80px
                }
                & > .imgbox{
                    display: flex;
                    justify-content: space-between;
                    padding:0 100px
                }

            }
        }
        
    }
    .contentbox{
        position: fixed;
        right: 0;
        top:60%;
        z-index: 6;

        .wxbtn{
            position: relative;

            div:nth-child(1){
                width:80px;
                height: 80px;
                display: flex;
                justify-content:center;
                align-items: center;
                flex-direction: column;
                position: relative;
                z-index: 6;
                cursor: pointer;
                background:rgba(255,102,13,1);
                transition: all 0.25s;

                & > img{
                    width: 32px;
                }
                & > p{
                    margin:5px 0 0;
                    color: #fff;
                    font-size: 14px;
                }
            }
            div:nth-child(2){
                position: absolute;
                top:0;
                right: 0;
                width:250px;
                background: rgba(255,102,13,0.6);
                z-index:-1;
                text-align: left;
                opacity: 0;
                transition: opacity 0.25s;
                &>img{
                    width:125px;
                    margin: 25px 25px 5px;
                }
                &>p{
                    margin: 0;
                    font-size: 13px;
                    color: #fff;
                    padding: 0px 43px 25px; 
                }
            }
            div:nth-child(1):hover{
                background: #ff660dc2;
            }
            div:nth-child(1):hover+div:nth-child(2){
                z-index: 5;
                opacity: 1;
            }
        }
        .qqbtn{
            position: relative;
            cursor: pointer;

            a{
                width:80px;
                height: 80px;
                display: flex;
                justify-content:center;
                align-items: center;
                flex-direction: column;
                position: relative;
                z-index: 6;
                background:rgba(255,102,13,1);
                text-decoration: none;
                transition: all 0.25s;

                & > img{
                    width: 32px;
                }
                & > p{
                    margin:5px 0 0;
                    color: #fff;
                    font-size: 13px;
                }
            }
            a:hover{
                background: #ff660dc2;
            }
        }
        
    }
    .bottomtanc{
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background: rgba(0,0,0,0.7);
        padding-bottom: 4.0rem;
        z-index: 9;
        
        transition: all 0.25s;

        &>p{
            font-size: 1.8rem; 
            color: #fff;
            text-align: center;
            font-size: 2.2rem;
            padding: 3.0rem 0;
            margin:0;
        }
        &>div{
            width: 31.25rem;
            height: 3.5rem;
            margin: auto;
            border-radius: 8px;
            background: #fff;
            display: flex;

            input {
                width: 20.65rem;
                border: none;
                font-size: 1.2rem;
                height: 2.5rem;
                line-height: 2.5rem;
                margin-top: 0.5rem;
                padding-left: 1.5rem;
            }
            input:focus{
                outline: 0;
            }
            &>div{
                background: #FD5619;
                color: #fff;
                display: block;
                height: 3.4rem;
                line-height: 3.4rem;
                float: right;
                width: 10.5rem;
                border-radius: 5px;
                margin-right: 1px;
                margin-top: 1px;
                font-size: 1.2rem;
                cursor: pointer;
            }
        }        
        &> i{
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 36px;
            z-index: 10;
            color: #fff;
            cursor: pointer;
        }

    }
    .bottomtanc.activate{
        bottom:0
    }
    // 临时提醒使用
    .top-nav .nav-content .navbox button{
        position: relative;

        .ps{
            position: absolute;
            top: 50px;
            right: 0;
            background: #fff;
            min-width: 200px;
            border-radius: 4px;
            border: 1px solid #ebeef5;
            padding: 12px;
            z-index: 2000;
            color: #333;
            line-height: 1.4;
            text-align: justify;
            font-size: 20px;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
            word-break: break-all;

            i{
                display: block;
                width: 10px;
                height: 10px;
                background: #fff;
                position: absolute;
                top: -6px;
                right: 30px;
                transform: rotate(-45deg);
            }
        }
    }
</style>
